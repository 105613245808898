@import (reference) '../../style/themes/index';
@menu-prefix-cls: ~'@{ant-prefix}-menu';

.@{menu-prefix-cls} {
  // Danger
  &-item-danger&-item {
    color: @menu-highlight-danger-color;

    &:hover,
    &-active {
      color: @menu-highlight-danger-color;
    }

    &:active {
      background: @menu-item-active-danger-bg;
    }

    &-selected {
      color: @menu-highlight-danger-color;

      > a,
      > a:hover {
        color: @menu-highlight-danger-color;
      }
    }

    .@{menu-prefix-cls}:not(.@{menu-prefix-cls}-horizontal) &-selected {
      background-color: @menu-item-active-danger-bg;
    }

    .@{menu-prefix-cls}-inline &::after {
      border-right-color: @menu-highlight-danger-color;
    }
  }

  // ==================== Dark ====================
  &-dark &-item-danger&-item {
    &,
    &:hover,
    & > a {
      color: @menu-dark-danger-color;
    }
  }

  &-dark&-dark:not(&-horizontal) &-item-danger&-item-selected {
    color: @menu-dark-highlight-color;
    background-color: @menu-dark-item-active-danger-bg;
  }
}
