@import '../../style/themes/index';

.@{list-prefix-cls}-bordered {
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
  .@{list-prefix-cls}-header {
    padding-right: @padding-lg;
    padding-left: @padding-lg;
  }

  .@{list-prefix-cls}-footer {
    padding-right: @padding-lg;
    padding-left: @padding-lg;
  }

  .@{list-prefix-cls}-item {
    padding-right: @padding-lg;
    padding-left: @padding-lg;
  }

  .@{list-prefix-cls}-pagination {
    margin: @margin-md @margin-lg;
  }

  &.@{list-prefix-cls}-sm {
    .@{list-prefix-cls}-item {
      padding: @list-item-padding-sm;
    }
    .@{list-prefix-cls}-header,
    .@{list-prefix-cls}-footer {
      padding: @list-item-padding-sm;
    }
  }

  &.@{list-prefix-cls}-lg {
    .@{list-prefix-cls}-item {
      padding: @list-item-padding-lg;
    }
    .@{list-prefix-cls}-header,
    .@{list-prefix-cls}-footer {
      padding: @list-item-padding-lg;
    }
  }
}
