@import '../../style/themes/index';
@import '../../style/mixins/index';

@segmented-prefix-cls: ~'@{ant-prefix}-segmented';

.@{segmented-prefix-cls} {
  &&-rtl {
    direction: rtl;
  }

  &&-rtl &-item-icon {
    margin-right: 0;
    margin-left: 6px;
  }
}
