.@{alert-prefix-cls} {
  &&-rtl {
    direction: rtl;
  }

  &-icon {
    .@{alert-prefix-cls}-rtl & {
      margin-right: auto;
      margin-left: @margin-xs;
    }
  }

  &-action {
    .@{alert-prefix-cls}-rtl & {
      margin-right: @margin-xs;
      margin-left: auto;
    }
  }

  &-close-icon {
    .@{alert-prefix-cls}-rtl & {
      margin-right: @margin-xs;
      margin-left: auto;
    }
  }

  &-with-description {
    .@{alert-prefix-cls}-rtl& {
      padding-right: @alert-with-description-icon-size;
      padding-left: @alert-with-description-padding-vertical;
    }

    .@{alert-prefix-cls}-icon {
      .@{alert-prefix-cls}-rtl& {
        margin-right: auto;
        margin-left: @alert-with-description-padding-vertical;
      }
    }
  }
}
