// We can not import reference of `./index` directly since it will make dead loop in less
@import (reference) '../../style/themes/index';
@cascader-prefix-cls: ~'@{ant-prefix}-cascader';

.@{cascader-prefix-cls}-rtl {
  .@{cascader-prefix-cls}-menu-item {
    &-expand-icon,
    &-loading-icon {
      margin-right: @padding-xss;
      margin-left: 0;
    }
  }

  .@{cascader-prefix-cls}-checkbox {
    top: 0;
    margin-right: 0;
    margin-left: @padding-xs;
  }
}
