.roundedArrow(@width, @outer-radius, @bg-color: var(--antd-arrow-background-color)) {
  @corner-height: unit(((@outer-radius) * (1 - 1 / sqrt(2))));

  @width-without-unit: unit(@width);
  @outer-radius-without-unit: unit(@outer-radius);
  @inner-radius-without-unit: unit(@arrow-border-radius);

  @a-x: @width-without-unit - @corner-height;
  @a-y: 2 * @width-without-unit + @corner-height;
  @b-x: @a-x + @outer-radius-without-unit * (1 / sqrt(2));
  @b-y: 2 * @width-without-unit;
  @c-x: 2 * @width-without-unit - @inner-radius-without-unit;
  @c-y: 2 * @width-without-unit;
  @d-x: 2 * @width-without-unit;
  @d-y: 2 * @width-without-unit - @inner-radius-without-unit;
  @e-x: 2 * @width-without-unit;
  @e-y: @f-y + @outer-radius-without-unit * (1 / sqrt(2));
  @f-x: 2 * @width-without-unit + @corner-height;
  @f-y: @width-without-unit - @corner-height;
  @g-x: @f-x - 1;
  @g-y: @f-y;
  @h-x: @a-x;
  @h-y: @a-y - 1;

  border-radius: 0 0 @arrow-border-radius;
  pointer-events: none;

  &::before {
    position: absolute;
    top: -@width;
    left: -@width;
    width: @width * 3;
    height: @width * 3;
    background: @bg-color;
    // Hack firefox: https://github.com/ant-design/ant-design/pull/33710#issuecomment-1015287825
    background-repeat: no-repeat;
    background-position: ceil(-@width + 1px) ceil(-@width + 1px);
    content: '';
    clip-path: inset(33% 33%); // For browsers that do not support path()
    clip-path: path(
      'M @{a-x} @{a-y} A @{outer-radius-without-unit} @{outer-radius-without-unit} 0 0 1 @{b-x} @{b-y} L @{c-x} @{c-y} A @{inner-radius-without-unit} @{inner-radius-without-unit} 0 0 0 @{d-x} @{d-y} L @{e-x} @{e-y} A @{outer-radius-without-unit} @{outer-radius-without-unit} 0 0 1 @{f-x} @{f-y} L @{g-x} @{g-y} L @{h-x} @{h-y} Z'
    );
  }
}
