@progress-prefix-cls: ~'@{ant-prefix}-progress';

.@{steps-prefix-cls}-with-progress {
  .@{steps-prefix-cls}-item {
    padding-top: 4px;

    & > .@{steps-prefix-cls}-item-container > .@{steps-prefix-cls}-item-tail {
      top: 4px;
      left: @steps-vertical-tail-width + 3;
    }
  }

  &,
  &.@{steps-prefix-cls}-small {
    &.@{steps-prefix-cls}-horizontal .@{steps-prefix-cls}-item:first-child {
      padding-bottom: 4px;
      padding-left: 4px;
    }
  }

  &.@{steps-prefix-cls}-small
    > .@{steps-prefix-cls}-item
    > .@{steps-prefix-cls}-item-container
    > .@{steps-prefix-cls}-item-tail {
    left: @steps-vertical-tail-width-sm + 3;
  }

  &.@{steps-prefix-cls}-vertical .@{steps-prefix-cls}-item {
    padding-left: 4px;
  }

  &.@{steps-prefix-cls}-label-vertical {
    .@{steps-prefix-cls}-item .@{steps-prefix-cls}-item-tail {
      top: 14px !important;
    }
  }

  .@{steps-prefix-cls}-item-icon {
    position: relative;

    .@{progress-prefix-cls} {
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
    }
  }
}
