@import 'box';

.modal-mask() {
  pointer-events: none;

  &.@{ant-prefix}-zoom-enter,
  &.@{ant-prefix}-zoom-appear {
    transform: none; // reset scale avoid mousePosition bug
    opacity: 0;
    animation-duration: @animation-duration-slow;
    user-select: none; // https://github.com/ant-design/ant-design/issues/11777
  }

  &-mask {
    .box(fixed);
    z-index: @zindex-modal-mask;
    height: 100%;
    background-color: @modal-mask-bg;

    &-hidden {
      display: none;
    }
  }

  &-wrap {
    .box(fixed);
    overflow: auto;
    outline: 0;
  }
}
