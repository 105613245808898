@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../button/style/mixin';
@import './mixin';

@search-prefix: ~'@{ant-prefix}-input-search';

.@{search-prefix} {
  .@{ant-prefix}-input {
    &:hover,
    &:focus {
      border-color: @input-hover-border-color;

      + .@{ant-prefix}-input-group-addon .@{search-prefix}-button:not(.@{ant-prefix}-btn-primary) {
        border-left-color: @input-hover-border-color;
      }
    }
  }

  .@{ant-prefix}-input-affix-wrapper {
    border-radius: 0;
  }

  // fix slight height diff in Firefox:
  // https://ant.design/components/auto-complete-cn/#components-auto-complete-demo-certain-category
  .@{ant-prefix}-input-lg {
    line-height: @line-height-base - 0.0002;
  }

  > .@{ant-prefix}-input-group {
    > .@{ant-prefix}-input-group-addon:last-child {
      left: -1px;
      padding: 0;
      border: 0;

      .@{search-prefix}-button {
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0 @border-radius-base @border-radius-base 0;
      }

      .@{search-prefix}-button:not(.@{ant-prefix}-btn-primary) {
        color: @text-color-secondary;

        &.@{ant-prefix}-btn-loading::before {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  &-button {
    height: @input-height-base;

    &:hover,
    &:focus {
      z-index: 1;
    }
  }

  &-large &-button {
    height: @input-height-lg;
  }

  &-small &-button {
    height: @input-height-sm;
  }

  // ===================== Compact Item Customized Styles =====================
  &.@{input-prefix-cls}-compact-item {
    &:not(.@{input-prefix-cls}-compact-item-rtl) {
      &:not(.@{input-prefix-cls}-compact-last-item) {
        .@{input-prefix-cls}-group-addon {
          .@{input-prefix-cls}-search-button {
            margin-right: -@border-width-base;
            border-radius: 0;
          }
        }
      }
    }

    &:not(.@{input-prefix-cls}-compact-first-item) {
      .@{input-prefix-cls},
      .@{input-prefix-cls}-affix-wrapper {
        border-radius: 0;
      }
    }

    > .@{input-prefix-cls}-group-addon .@{input-prefix-cls}-search-button,
    > .@{input-prefix-cls},
    .@{input-prefix-cls}-affix-wrapper {
      &:hover,
      &:focus,
      &:active {
        z-index: 2;
      }
    }

    > .@{input-prefix-cls}-affix-wrapper-focused {
      z-index: 2;
    }
  }
  // ===================== For RTL Compact Item Customized Styles =====================
  &.@{input-prefix-cls}-compact-item-rtl {
    &:not(.@{input-prefix-cls}-compact-last-item) {
      .@{input-prefix-cls}-group-addon:last-child {
        .@{input-prefix-cls}-search-button {
          margin-left: -@border-width-base;
          border-radius: 0;
        }
      }
    }
  }
}
