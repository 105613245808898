@import '../../style/themes/index';
@import '../../style/mixins/index';
@import './mixin';

.@{row-prefix-cls} {
  &-rtl {
    direction: rtl;
  }
}

// mixin
.loop-grid-columns(@index, @class) when (@index > 0) {
  .@{col-prefix-cls}@{class}-push-@{index} {
    // reset property in RTL direction
    &.@{col-prefix-cls}-rtl {
      right: percentage((@index / @grid-columns));
      left: auto;
    }
  }

  .@{col-prefix-cls}@{class}-pull-@{index} {
    // reset property in RTL direction
    &.@{col-prefix-cls}-rtl {
      right: auto;
      left: percentage((@index / @grid-columns));
    }
  }

  .@{col-prefix-cls}@{class}-offset-@{index} {
    // reset property in RTL direction
    &.@{col-prefix-cls}-rtl {
      margin-right: percentage((@index / @grid-columns));
      margin-left: 0;
    }
  }
}

.loop-grid-columns(@index, @class) when (@index = 0) {
  .@{col-prefix-cls}-push-@{index} {
    // reset property in RTL direction
    &.@{col-prefix-cls}-rtl {
      right: auto;
    }
  }

  .@{col-prefix-cls}-pull-@{index} {
    &.@{col-prefix-cls}-rtl {
      left: auto;
    }
  }

  .@{col-prefix-cls}@{class}-push-@{index} {
    &.@{col-prefix-cls}-rtl {
      right: auto;
    }
  }

  .@{col-prefix-cls}@{class}-pull-@{index} {
    &.@{col-prefix-cls}-rtl {
      left: auto;
    }
  }

  .@{col-prefix-cls}@{class}-offset-@{index} {
    &.@{col-prefix-cls}-rtl {
      margin-right: 0;
    }
  }
}
