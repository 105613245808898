@import '../../style/themes/index';
@import '../../style/mixins/index';

@space-compact-prefix-cls: ~'@{ant-prefix}-space-compact';

.@{space-compact-prefix-cls} {
  display: inline-flex;

  &-block {
    display: flex;
    width: 100%;
  }

  &-vertical {
    flex-direction: column;
  }
}
