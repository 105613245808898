@import '../../style/mixins/index';

@row-prefix-cls: ~'@{ant-prefix}-row';
@col-prefix-cls: ~'@{ant-prefix}-col';

// mixins for grid system
// ------------------------

.loop-grid-columns(@index, @class) when (@index > 0) {
  .@{col-prefix-cls}@{class}-@{index} {
    display: block;
    flex: 0 0 percentage((@index / @grid-columns));
    max-width: percentage((@index / @grid-columns));
  }
  .@{col-prefix-cls}@{class}-push-@{index} {
    left: percentage((@index / @grid-columns));
  }
  .@{col-prefix-cls}@{class}-pull-@{index} {
    right: percentage((@index / @grid-columns));
  }
  .@{col-prefix-cls}@{class}-offset-@{index} {
    margin-left: percentage((@index / @grid-columns));
  }
  .@{col-prefix-cls}@{class}-order-@{index} {
    order: @index;
  }
  .loop-grid-columns((@index - 1), @class);
}

.loop-grid-columns(@index, @class) when (@index = 0) {
  .@{col-prefix-cls}@{class}-@{index} {
    display: none;
  }
  .@{col-prefix-cls}-push-@{index} {
    left: auto;
  }
  .@{col-prefix-cls}-pull-@{index} {
    right: auto;
  }
  .@{col-prefix-cls}@{class}-push-@{index} {
    left: auto;
  }
  .@{col-prefix-cls}@{class}-pull-@{index} {
    right: auto;
  }
  .@{col-prefix-cls}@{class}-offset-@{index} {
    margin-left: 0;
  }
  .@{col-prefix-cls}@{class}-order-@{index} {
    order: 0;
  }
}

.make-grid(@class: ~'') {
  .loop-grid-columns(@grid-columns, @class);
}
