@import '../../style/themes/index';

@drawer-prefix-cls: ~'@{ant-prefix}-drawer';

.@{drawer-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-close {
    .@{drawer-prefix-cls}-rtl & {
      margin-right: 0;
      margin-left: 12px;
    }
  }
}
