@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../checkbox/style/mixin';
@import './mixin';
@import './directory';

@tree-prefix-cls: ~'@{ant-prefix}-tree';
@tree-node-prefix-cls: ~'@{tree-prefix-cls}-treenode';

.antCheckboxFn(@checkbox-prefix-cls: ~'@{ant-prefix}-tree-checkbox');

.@{tree-prefix-cls} {
  .antTreeFn(@tree-prefix-cls);
}

@import './rtl';

@root-entry-name: default;