.@{layout-prefix-cls}-sider-light {
  background: @layout-sider-background-light;
  .@{layout-prefix-cls}-sider-trigger {
    color: @layout-trigger-color-light;
    background: @layout-trigger-background-light;
  }
  .@{layout-prefix-cls}-sider-zero-width-trigger {
    color: @layout-trigger-color-light;
    background: @layout-trigger-background-light;
  }
}
