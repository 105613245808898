@import '../../style/themes/index';

@drawer-prefix-cls: ~'@{ant-prefix}-drawer';
@picker-prefix-cls: ~'@{ant-prefix}-picker';
@drawer-animation-ease: @ease-out-quint;

.@{drawer-prefix-cls} {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal;
  pointer-events: none;

  &-inline {
    position: absolute;
  }

  // ====================== Mask ======================
  &-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @zindex-modal;
    background: @modal-mask-bg;
    pointer-events: auto;
  }

  // ==================== Content =====================
  &-content-wrapper {
    position: absolute;
    z-index: @zindex-modal;
    transition: all @animation-duration-slow;

    &-hidden {
      display: none;
    }

    // Placement
    .@{drawer-prefix-cls}-left > & {
      top: 0;
      bottom: 0;
      left: 0;
      box-shadow: @shadow-1-right;
    }

    .@{drawer-prefix-cls}-right > & {
      top: 0;
      right: 0;
      bottom: 0;
      box-shadow: @shadow-1-left;
    }

    .@{drawer-prefix-cls}-top > & {
      top: 0;
      right: 0;
      left: 0;
      box-shadow: @shadow-1-down;
    }

    .@{drawer-prefix-cls}-bottom > & {
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: @shadow-1-up;
    }
  }

  &-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: @drawer-bg;
    pointer-events: auto;
  }

  // ===================== Panel ======================
  &-wrapper-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  // Header
  &-header {
    display: flex;
    flex: 0;
    align-items: center;
    padding: @drawer-header-padding;
    font-size: @drawer-title-font-size;
    line-height: @drawer-title-line-height;
    border-bottom: @border-width-base @border-style-base @border-color-split;

    &-title {
      display: flex;
      flex: 1;
      align-items: center;
      min-width: 0;
      min-height: 0;
    }
  }

  &-extra {
    flex: none;
  }

  &-close {
    display: inline-block;
    margin-right: 12px;
    color: @modal-close-color;
    font-weight: 700;
    font-size: @font-size-lg;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color @animation-duration-slow;
    text-rendering: auto;

    &:focus,
    &:hover {
      color: @icon-color-hover;
      text-decoration: none;
    }
  }

  &-title {
    flex: 1;
    margin: 0;
    color: @heading-color;
    font-weight: 500;
    font-size: @drawer-title-font-size;
    line-height: @drawer-title-line-height;
  }

  // Body
  &-body {
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: @drawer-body-padding;
    overflow: auto;
  }

  // Footer
  &-footer {
    flex-shrink: 0;
    padding: @drawer-footer-padding-vertical @drawer-footer-padding-horizontal;
    border-top: @border-width-base @border-style-base @border-color-split;
  }
}
