@import '../../style/themes/index';
@import '../../style/mixins/index';

@tab-prefix-cls: ~'@{ant-prefix}-tabs';

.@{tab-prefix-cls}-dropdown {
  .reset-component();

  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: @zindex-dropdown;
  display: block;

  &-hidden {
    display: none;
  }

  &-menu {
    max-height: 200px;
    margin: 0;
    padding: @dropdown-edge-child-vertical-padding 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    list-style-type: none;
    background-color: @dropdown-menu-bg;
    background-clip: padding-box;
    border-radius: @border-radius-base;
    outline: none;
    box-shadow: @box-shadow-base;

    &-item {
      display: flex;
      align-items: center;
      min-width: 120px;
      margin: 0;
      padding: @dropdown-vertical-padding @control-padding-horizontal;
      overflow: hidden;
      color: @text-color;
      font-weight: normal;
      font-size: @dropdown-font-size;
      line-height: @dropdown-line-height;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: all 0.3s;

      > span {
        flex: 1;
        white-space: nowrap;
      }

      &-remove {
        flex: none;
        margin-left: @margin-sm;
        color: @text-color-secondary;
        font-size: @font-size-sm;
        background: transparent;
        border: 0;
        cursor: pointer;

        &:hover {
          color: @tabs-hover-color;
        }
      }

      &:hover {
        background: @item-hover-bg;
      }

      &-disabled {
        &,
        &:hover {
          color: @disabled-color;
          background: transparent;
          cursor: not-allowed;
        }
      }
    }
  }
}
