@import '../../input/style/mixin';

@mention-prefix-cls: ~'@{ant-prefix}-mentions';
@input-prefix-cls: ~'@{ant-prefix}-input';

.@{mention-prefix-cls} {
  &-status-error {
    .status-color(@mention-prefix-cls, @error-color, @error-color, @input-bg, @error-color-hover, @error-color-outline);
    .status-color-common(@input-prefix-cls, @error-color, @error-color, @input-bg, @error-color-hover, @error-color-outline);
  }

  &-status-warning {
    .status-color(@mention-prefix-cls, @warning-color, @warning-color, @input-bg, @warning-color-hover, @warning-color-outline);
    .status-color-common(@input-prefix-cls, @warning-color, @warning-color, @input-bg, @warning-color-hover, @warning-color-outline);
  }
}
