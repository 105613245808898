@import '../../input/style/mixin';

@picker-prefix-cls: ~'@{ant-prefix}-picker';

.picker-status-color(
  @text-color: @input-color;
  @border-color: @input-border-color;
  @background-color: @input-bg;
  @hoverBorderColor: @primary-color-hover;
  @outlineColor: @primary-color-outline;
) {
  &.@{picker-prefix-cls} {
    &,
    &:not(.@{picker-prefix-cls}-disabled):hover {
      background-color: @background-color;
      border-color: @border-color;
    }

    &-focused,
    &:focus {
      .active(@text-color, @hoverBorderColor, @outlineColor);
    }

    .@{picker-prefix-cls}-active-bar {
      background: @hoverBorderColor;
    }
  }
}

.@{picker-prefix-cls} {
  &-status-error {
    .picker-status-color(@error-color, @error-color, @input-bg, @error-color-hover, @error-color-outline);
  }

  &-status-warning {
    .picker-status-color(@warning-color, @warning-color, @input-bg, @warning-color-hover, @warning-color-outline);
  }
}
