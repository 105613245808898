@import (reference) '../../style/themes/index';

@form-prefix-cls: ~'@{ant-prefix}-form';
@form-item-prefix-cls: ~'@{form-prefix-cls}-item';

// ================================================================
// =                      Children Component                      =
// ================================================================
// FIXME: useless, remove in v5
.@{form-item-prefix-cls} {
  .@{ant-prefix}-input-number {
    + .@{form-prefix-cls}-text {
      margin-left: 8px;
    }
  }
}
