body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.logo {
  color: #fff;
  font-size: 20px;
}

.sergate {
  margin: 16px 0;
}
.sergate .ant-row {
  position: relative;
  flex-wrap: nowrap;
}
.sergate .sg-progress .ant-progress-outer {
  margin-right: calc(-3em - 8px);
  padding-right: calc(3em + 8px);
}
.sr-head {
  padding-top: 8px;
  padding-bottom: 8px;
}
.sr-head .ant-col {
  text-align: center;
}
.sr-body .ant-col {
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid #ebebeb;
}
.col-num {
  position: absolute;
  width: 16px;
  line-height: 40px;
  left: -16px;
  top: 0;
  text-align: right;
}

.sergate .lastUpdated {
  margin-top: 16px;
  text-align: center;
}

.sergate .anticon-check-circle {
  color: #389e0d;
}

.sergate .anticon-warning {
  color: #cf1322;
}

.ant-layout .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

body {
  padding-bottom: 69px;
}

.footer a {
  color: inherit;
}

.footer a:hover {
  color: #003a8c;
  text-decoration: underline;
}

.network-traffic-col {
  display: flex;
  justify-content: center;
}

.network-traffic-col span {
  display: inline-block;
}

.network-traffic-col .network-value-in {
    text-align: right;
}
.network-traffic-col .network-value-out {
    text-align: left;
}


@media (max-width: 768px) {
  .logo {
    text-align: center;
  }
  .sr-head {
    font-size: 0.8rem;
  }
  .sr-body .ant-col {
    font-size: 0.8rem;
  }
  .sergate .ant-tag {
    margin-right: 0;
  }
  .network-traffic-col {
    display: none;
  }
}

@media (min-width: 769px) {
  .network-traffic-col .network-value-in {
    min-width: 50px; 
  }
  .network-traffic-col .network-value-out {
    min-width: 50px; 
  }
  .network-traffic-col .separator {
    margin: 0 5px;
  }
}

@media (max-width: 900px) {
  .sergate .ant-col .ant-progress-outer {
    display: none;
  }
  .sergate .ant-col .ant-progress-text {
    margin-left: 0;
  }

  .sr-head {
    font-size: 12px;
  }
}
