@import './size';
@import (reference) '../../style/themes/index';
@table-prefix-cls: ~'@{ant-prefix}-table';

@table-border: @border-width-base @border-style-base @table-border-color;

.@{table-prefix-cls}.@{table-prefix-cls}-bordered {
  // ============================ Title =============================
  > .@{table-prefix-cls}-title {
    border: @table-border;
    border-bottom: 0;
  }

  > .@{table-prefix-cls}-container {
    // ============================ Content ============================
    border-left: @table-border;

    > .@{table-prefix-cls}-content,
    > .@{table-prefix-cls}-header,
    > .@{table-prefix-cls}-body,
    > .@{table-prefix-cls}-summary {
      > table {
        // ============================= Cell =============================
        > thead > tr > th,
        > tbody > tr > td,
        > tfoot > tr > th,
        > tfoot > tr > td {
          border-right: @table-border;
        }
        // ============================ Header ============================
        > thead {
          > tr:not(:last-child) > th {
            border-bottom: @border-width-base @border-style-base @table-border-color;
          }

          > tr > th {
            &::before {
              background-color: transparent !important;
            }
          }
        }

        // Fixed right should provides additional border
        > thead > tr,
        > tbody > tr,
        > tfoot > tr {
          > .@{table-prefix-cls}-cell-fix-right-first::after {
            border-right: @table-border;
          }
        }
      }

      // ========================== Expandable ==========================
      > table > tbody > tr > td {
        > .@{table-prefix-cls}-expanded-row-fixed {
          margin: -@table-padding-vertical (-@table-padding-horizontal - @border-width-base);

          &::after {
            position: absolute;
            top: 0;
            right: @border-width-base;
            bottom: 0;
            border-right: @table-border;
            content: '';
          }
        }
      }
    }

    > .@{table-prefix-cls}-content,
    > .@{table-prefix-cls}-header {
      > table {
        border-top: @table-border;
      }
    }
  }

  &.@{table-prefix-cls}-scroll-horizontal {
    > .@{table-prefix-cls}-container > .@{table-prefix-cls}-body {
      > table > tbody {
        > tr.@{table-prefix-cls}-expanded-row,
        > tr.@{table-prefix-cls}-placeholder {
          > td {
            border-right: 0;
          }
        }
      }
    }
  }

  // Size related
  &.@{table-prefix-cls}-middle {
    > .@{table-prefix-cls}-container {
      > .@{table-prefix-cls}-content,
      > .@{table-prefix-cls}-body {
        > table > tbody > tr > td {
          > .@{table-prefix-cls}-expanded-row-fixed {
            margin: -@table-padding-vertical-md (-@table-padding-horizontal-md - @border-width-base);
          }
        }
      }
    }
  }

  &.@{table-prefix-cls}-small {
    > .@{table-prefix-cls}-container {
      > .@{table-prefix-cls}-content,
      > .@{table-prefix-cls}-body {
        > table > tbody > tr > td {
          > .@{table-prefix-cls}-expanded-row-fixed {
            margin: -@table-padding-vertical-sm (-@table-padding-horizontal-sm - @border-width-base);
          }
        }
      }
    }
  }

  // ============================ Footer ============================
  > .@{table-prefix-cls}-footer {
    border: @table-border;
    border-top: 0;
  }
}

.@{table-prefix-cls}-cell {
  // ============================ Nested ============================
  .@{table-prefix-cls}-container:first-child {
    // :first-child to avoid the case when bordered and title is set
    border-top: 0;
  }

  // https://github.com/ant-design/ant-design/issues/35577
  &-scrollbar:not([rowspan]) {
    box-shadow: 0 @border-width-base 0 @border-width-base @table-header-bg;
  }
}
