.accessibility-focus-dark() {
  box-shadow: 0 0 0 2px @primary-7;
}

.@{menu-prefix-cls} {
  &&-root:focus-visible {
    .accessibility-focus-dark();
  }

  &-dark &-item,
  &-dark &-submenu-title {
    &:focus-visible {
      .accessibility-focus-dark();
    }
  }

  // dark theme
  &&-dark,
  &-dark &-sub,
  &&-dark &-sub {
    color: @menu-dark-color;
    background: @menu-dark-bg;
    .@{menu-prefix-cls}-submenu-title .@{menu-prefix-cls}-submenu-arrow {
      opacity: 0.45;
      transition: all 0.3s;

      &::after,
      &::before {
        background: @menu-dark-arrow-color;
      }
    }
  }

  &-dark&-submenu-popup {
    background: transparent;
  }

  &-dark &-inline&-sub {
    background: @menu-dark-inline-submenu-bg;
  }

  &-dark&-horizontal {
    border-bottom: 0;
  }

  &-dark&-horizontal > &-item,
  &-dark&-horizontal > &-submenu {
    top: 0;
    margin-top: 0;
    padding: @menu-item-padding;
    border-color: @menu-dark-bg;
    border-bottom: 0;
  }

  &-dark&-horizontal > &-item:hover {
    background-color: @menu-dark-item-active-bg;
  }

  &-dark&-horizontal > &-item > a::before {
    bottom: 0;
  }

  &-dark &-item,
  &-dark &-item-group-title,
  &-dark &-item > a,
  &-dark &-item > span > a {
    color: @menu-dark-color;
  }

  &-dark&-inline,
  &-dark&-vertical,
  &-dark&-vertical-left,
  &-dark&-vertical-right {
    border-right: 0;
  }

  &-dark&-inline &-item,
  &-dark&-vertical &-item,
  &-dark&-vertical-left &-item,
  &-dark&-vertical-right &-item {
    left: 0;
    margin-left: 0;
    border-right: 0;

    &::after {
      border-right: 0;
    }
  }

  &-dark&-inline &-item,
  &-dark&-inline &-submenu-title {
    width: 100%;
  }

  &-dark &-item:hover,
  &-dark &-item-active,
  &-dark &-submenu-active,
  &-dark &-submenu-open,
  &-dark &-submenu-selected,
  &-dark &-submenu-title:hover {
    color: @menu-dark-highlight-color;
    background-color: transparent;

    > a,
    > span > a {
      color: @menu-dark-highlight-color;
    }
    > .@{menu-prefix-cls}-submenu-title {
      > .@{menu-prefix-cls}-submenu-arrow {
        opacity: 1;

        &::after,
        &::before {
          background: @menu-dark-highlight-color;
        }
      }
    }
  }

  &-dark &-item:hover {
    background-color: @menu-dark-item-hover-bg;
  }

  &-dark&-dark:not(&-horizontal) &-item-selected {
    background-color: @menu-dark-item-active-bg;
  }

  &-dark &-item-selected {
    color: @menu-dark-highlight-color;
    border-right: 0;

    &::after {
      border-right: 0;
    }

    > a,
    > span > a,
    > a:hover,
    > span > a:hover {
      color: @menu-dark-highlight-color;
    }

    .@{menu-prefix-cls}-item-icon,
    .@{iconfont-css-prefix} {
      color: @menu-dark-selected-item-icon-color;

      + span {
        color: @menu-dark-selected-item-text-color;
      }
    }
  }

  &&-dark &-item-selected,
  &-submenu-popup&-dark &-item-selected {
    background-color: @menu-dark-item-active-bg;
  }

  // Disabled state sets text to dark gray and nukes hover/tab effects
  &-dark &-item-disabled,
  &-dark &-submenu-disabled {
    &,
    > a,
    > span > a {
      color: @disabled-color-dark !important;
      opacity: 0.8;
    }
    > .@{menu-prefix-cls}-submenu-title {
      color: @disabled-color-dark !important;
      > .@{menu-prefix-cls}-submenu-arrow {
        &::before,
        &::after {
          background: @disabled-color-dark !important;
        }
      }
    }
  }
}
