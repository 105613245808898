@import (reference) '../../style/themes/index';

@dropdown-prefix-cls: ~'@{ant-prefix}-dropdown';

.@{dropdown-prefix-cls}-menu-item {
  &&-danger {
    color: @error-color;

    &:hover {
      color: @text-color-inverse;
      background-color: @error-color;
    }
  }
}
