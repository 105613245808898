.operation-unit() {
  color: @link-color;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;

  &:focus-visible,
  &:hover {
    color: @link-hover-color;
  }

  &:active {
    color: @link-active-color;
  }
}
