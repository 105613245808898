// Compatibility for browsers.

// Placeholder text
.placeholder(@color: @input-placeholder-color) {
  &::placeholder {
    color: @color;
    user-select: none; // https://github.com/ant-design/ant-design/pull/32639
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}
