@import (reference) '../../style/themes/index';

@form-prefix-cls: ~'@{ant-prefix}-form';
@form-item-prefix-cls: ~'@{form-prefix-cls}-item';

.@{form-item-prefix-cls} {
  // ================================================================
  // =                            Status                            =
  // ================================================================

  /* Some non-status related component style is in `components.less` */

  // ========================= Explain =========================

  /* To support leave along ErrorList. We add additional className to handle explain style */
  &-explain {
    &-error {
      color: @error-color;
    }

    &-warning {
      color: @warning-color;
    }
  }

  &-has-feedback {
    // ======================== Switch =========================
    .@{ant-prefix}-switch {
      margin: 2px 0 4px;
    }
  }

  // ======================== Warning ========================
  &-has-warning {
    .form-control-validation(@warning-color; @warning-color; @form-warning-input-bg; @warning-color-hover; @warning-color-outline);
  }

  // ========================= Error =========================
  &-has-error {
    .form-control-validation(@error-color; @error-color; @form-error-input-bg; @error-color-hover; @error-color-outline);
  }
}
