@import '../../style/themes/index';
@import '../../style/mixins/index';

@space-prefix-cls: ~'@{ant-prefix}-space';

.@{space-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-compact-rtl {
    direction: rtl;
  }
}
