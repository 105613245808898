@import '../../style/themes/index';
@import '../../style/mixins/index';

@collapse-prefix-cls: ~'@{ant-prefix}-collapse';

.@{collapse-prefix-cls} {
  &-rtl {
    direction: rtl;

    // Expand Icon end
    &.@{collapse-prefix-cls}.@{collapse-prefix-cls}-icon-position-end {
      & > .@{collapse-prefix-cls}-item {
        > .@{collapse-prefix-cls}-header {
          position: relative;
          padding: @collapse-header-padding;
          padding-left: @collapse-header-padding-extra;

          .@{collapse-prefix-cls}-arrow {
            position: absolute;
            top: 50%;
            right: auto;
            left: @padding-md;
            margin: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  & > &-item {
    > .@{collapse-prefix-cls}-header {
      .@{collapse-prefix-cls}-rtl & {
        padding: @collapse-header-padding;
        padding-right: @collapse-header-padding-extra;
      }

      .@{collapse-prefix-cls}-arrow {
        .@{collapse-prefix-cls}-rtl& {
          margin-right: 0;
          margin-left: @margin-sm;
        }

        & svg {
          .@{collapse-prefix-cls}-rtl& {
            transform: rotate(180deg);
          }
        }
      }

      .@{collapse-prefix-cls}-extra {
        .@{collapse-prefix-cls}-rtl& {
          margin-right: auto;
          margin-left: 0;
        }
      }
    }

    &.@{collapse-prefix-cls}-no-arrow {
      > .@{collapse-prefix-cls}-header {
        .@{collapse-prefix-cls}-rtl& {
          padding-right: @padding-sm;
          padding-left: 0;
        }
      }
    }
  }
}
