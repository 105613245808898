@import '../../style/themes/index';
@import '../../style/mixins/index';

@tag-prefix-cls: ~'@{ant-prefix}-tag';

.@{tag-prefix-cls} {
  &&-rtl {
    margin-right: 0;
    margin-left: 8px;
    direction: rtl;
    text-align: right;
  }

  &-close-icon {
    .@{tag-prefix-cls}-rtl & {
      margin-right: 3px;
      margin-left: 0;
    }
  }

  > .@{iconfont-css-prefix} + span,
  > span + .@{iconfont-css-prefix} {
    .@{tag-prefix-cls}-rtl& {
      margin-right: 7px;
      margin-left: 0;
    }
  }
}
